export const getCategoryUrl = (categoryId: string) => {
	switch (categoryId) {
		case '1':
			return 'cakes'
		case '2':
			return 'desserts'
		case '3':
			return 'pictures'
		case '4':
			return 'wedding/cakes'
		case '5':
			return 'wedding/loaves'
		default:
			return 'cakes'
	}
}