import { motion } from 'framer-motion'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuOption } from '../../../interfaces'
import DropdownOption from './DropdownOption'

const wrapperVariants = {
	open: {
		scaleY: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1
		}
	},
	closed: {
		scaleY: 0,
		transition: {
			when: 'afterChildren',
			staggerChildren: 0.1
		}
	}
}

interface IDropdownProps {
	item: MenuOption
}

const DropdownButton: FC<IDropdownProps> = ({ item }) => {
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()

	return (
		<motion.div
			animate={open ? 'open' : 'closed'}
			className="relative"
		>
			<motion.button
				whileHover={{ scale: 1.01 }}
				whileTap={{ scale: 0.8 }}
				onClick={() => setOpen(pv => !pv)}
				className="flex items-center gap-2 px-3 py-2 rounded-md text-indigo-50 bg-indigo-500 hover:bg-indigo-500 transition-colors"
			>
				<span className="font-medium text-sm hidden md:flex">{item.label}</span>
				<item.icon />
			</motion.button>
			{item.children && (
				<motion.ul
					initial={wrapperVariants.closed}
					variants={wrapperVariants}
					style={{ originY: 'top', translateX: '-50%' }}
					className="flex flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] left-[50%] w-48 overflow-hidden"
				>
					{item.children.map((value, index) => (
						<DropdownOption key={index} setOpen={setOpen} option={value} nav={navigate} />
					))}
				</motion.ul>
			)}
		</motion.div>
	)
}

export default DropdownButton
