import { useParams } from 'react-router-dom'
import AppHelmet from '../components/AppHelmet'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProductsResponse } from '../interfaces'

export default function ProductsPage() {
	const params = useParams()
	const { data } = useCachedKy<IProductsResponse>(`category-products/${params.id}`)
	return (
		<>
			<AppHelmet title={`${data?.category.title}`} />
			<ProductList title={`${data?.category.title}`} products={data?.products} />
		</>
	)
}
