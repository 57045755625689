import { FC } from 'react'
import { IScrollDownButtonProps } from './ScrollDownButton.interface'
import styles from './ScrollDownButton.module.css'

const ScrollDownButton: FC<IScrollDownButtonProps> = ({ className }) => {
	return <div className={`${styles.scrollDown} ${className}`}>
		<span />
		<span />
		<span />
	</div>
}

export default ScrollDownButton
