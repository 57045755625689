import { FC, lazy, PropsWithChildren, Suspense } from 'react'
import Header from './header'

const PhoneBanner = lazy(() => import( '../../components/banners/PhoneBanner'))

const Footer = lazy(() => import('./footer'))

const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className="min-h-screen bg-neutral-900">
			<Header />
			<main>{children}</main>
			<Footer />
			<Suspense fallback={null}><PhoneBanner /></Suspense>
		</div>
	)
}
export default Layout
