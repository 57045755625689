import { motion } from 'framer-motion'
import { Dispatch, FC, SetStateAction } from 'react'
import { MenuOption } from '../../../interfaces'

const itemVariants = {
	open: {
		opacity: 1,
		y: 0,
		transition: {
			when: 'beforeChildren'
		}
	},
	closed: {
		opacity: 0,
		y: -15,
		transition: {
			when: 'afterChildren'
		}
	}
}

const actionIconVariants = {
	open: { scale: 1, y: 0 },
	closed: { scale: 0, y: -7 }
}

interface IDropdownOptionProps {
	option: MenuOption
	setOpen: Dispatch<SetStateAction<boolean>>
	nav: (s: string) => void
}

const DropdownOption: FC<IDropdownOptionProps> = ({ option, setOpen, nav }) => {
	return (
		<motion.li
			variants={itemVariants}
			onClick={() => {
				nav(option.key)
				setOpen(false)
			}}
			className="flex items-center gap-2 w-full p-2 text-xs font-medium whitespace-nowrap rounded-md hover:bg-indigo-100 text-slate-700 hover:text-indigo-500 transition-colors cursor-pointer"
		>
			<motion.span variants={actionIconVariants}>
				<option.icon />
			</motion.span>
			<span>{option.label}</span>
		</motion.li>
	)
}

export default DropdownOption
