import { FC, PropsWithChildren } from 'react'

type HeadingProps = PropsWithChildren<{ className?: string }>

const Heading: FC<
	HeadingProps
> = ({
			 className,
			 children
		 }) => (
	<h1
		className={`text-center text-white text-3xl font-black uppercase my-6 ${className}`}
		children={children}
	/>
)

export default Heading
