
const splitArray = (initialArray?: any[]): [any[], any[], any[]] => {
	if (!initialArray) return [[], [], []]; // Перевірка на вхідний масив
	const chunkSize = Math.ceil(initialArray.length / 3);
	const result: [any[], any[], any[]] = [[], [], []];
	let startIndex = 0;
	for (let i = 0; i < 3; i++) {
		const endIndex = Math.min(startIndex + chunkSize, initialArray.length);
		result[i] = initialArray.slice(startIndex, endIndex);
		startIndex = endIndex;
	}
	return result;
};

export { splitArray }