import { FC, lazy, Suspense } from 'react'
import AppHelmet from '../components/AppHelmet'
import ProductsSlider from '../components/product/slider'
import { useCachedKy } from '../hooks'
import { ICategory, IProduct, IProductsResponse, IReview } from '../interfaces'


const CategoriesSection = lazy(() => import('../components/categories-section'))

const PopularProductBanner = lazy(() => import( '../components/banners/PopularProductBanner'))

const AboutUsSection = lazy(() => import( '../components/about'))

const ScrollVelocity = lazy(() => import( '../components/scroll-velocity'))

const TestimonialSection = lazy(() => import('../components/testmonials'))

const HomePage: FC = () => {
	const { data: cakes } = useCachedKy<IProductsResponse>('category-products/1/8')
	const { data: desserts } = useCachedKy<IProductsResponse>('category-products/2/8')
	const { data: categories } = useCachedKy<ICategory[]>('categories')
	const { data: reviews } = useCachedKy<IReview[]>('reviews/all')

	return (
		<>
			<AppHelmet title="Головна сторінка" />
			<div className="space-y-10">
				<ProductsSlider
					products={cakes?.products}
					title="Найпопулярніші тортики за сьогодні"
					button={{ url: `/products/${cakes?.category.id}`, message: 'Показати більше тортиків' }}
				/>
				<Suspense fallback={null}>
					<ScrollVelocity />
					<CategoriesSection categories={categories} />
					<PopularProductBanner />
				</Suspense>
				<ProductsSlider
					products={desserts?.products}
					title="Найпопулярніші десертики за сьогодні"
					button={{ url:`/products/${desserts?.category.id}`, message: 'Показати більше десертів' }}
				/>
				<Suspense fallback={null}>
					<AboutUsSection />
					<TestimonialSection reviews={reviews} />
				</Suspense>
			</div>
		</>
	)
}
export default HomePage
