import { motion } from 'framer-motion'
import { FC, useState } from 'react'
import { FiArrowRight, FiEye } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { imageUrl } from '../../data'
import { IProduct } from '../../interfaces'
import { getCategoryUrl } from '../../utils'
import Heading from '../heading/Heading'

interface IAboutProductProps {
	data?: IProduct,
	id: string
}

const AboutProduct: FC<IAboutProductProps> = ({ data, id }) => {
	const [imageLoaded, setImageLoaded] = useState(false)

	return (
		<section className="container mx-auto max-w-7xl pt-20">
			<Heading>Про товар:</Heading>
			<div
				className="flex flex-col lg:flex-row gap-x-12 gap-y-6 bg-neutral-800/70 rounded-xl border border-neutral-700 p-4 m-3"
				style={{ viewTransitionName: `productBox${id}` }}
			>
				<div className="lg:w-1/2 w-full" style={{ viewTransitionName: `productImage${id}` }}>
					{!imageLoaded && <div className="flex items-center justify-center h-full">
						<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500" />
					</div>
					}
					<img
						src={imageUrl + data?.urlToImg}
						alt=""
						className={`w-full rounded-2xl ${!imageLoaded && ' hidden'}`}
						onLoad={() => setImageLoaded(true)}
					/>
				</div>
				<div className="lg:w-1/2 w-full flex flex-col gap-y-4 text-center lg:text-start">
					<h1
						className="text-2xl lg:text-4xl text-neutral-100 font-semibold"
						style={{ viewTransitionName: `productTitle${id}` }}
					>
						{data?.title}
					</h1>
					<p
						dangerouslySetInnerHTML={{ __html: data?.description || '' }}
						className="leading-8 text-neutral-300"
					/>
					<div className="flex flex-wrap gap-1.5">
						{data?.visiting &&
							<motion.div
								initial={{ scale: 0.5, opacity: 0.5 }}
								whileInView={{ scale: 1, opacity: 0.8 }}
								viewport={{ once: true }}
								className="flex items-center gap-2 text-neutral-300 text-sm bg-neutral-700 rounded-xl max-w-[12rem] p-2">
								<FiEye />
								<p>Переглянуто {data?.visiting} раз</p>
							</motion.div>
						}
						{data?.categoryID &&
							<motion.div
								initial={{ scale: 0.5, opacity: 0.5 }}
								whileInView={{ scale: 1, opacity: 0.8 }}
								whileHover={{ scale: 1.05, opacity: 1 }}
								viewport={{ once: true }}
								className="text-neutral-300 text-sm bg-neutral-700 rounded-xl max-w-[12rem] p-2"
							>
								<Link to={`/${getCategoryUrl(data.categoryID)}`} className="flex items-center gap-2">
									<FiArrowRight />
									<span>{data.categoryName}</span>
								</Link>
							</motion.div>
						}
					</div>

					<div className="text-red-500 text-2xl lg:text-3xl font-bold">
						{[data?.price, data?.priceTitle].join(' ')}
					</div>

				</div>
			</div>
		</section>
	)
}
export default AboutProduct
