import { FC } from 'react'
import { GiCakeSlice, GiCupcake, GiStairsCake } from 'react-icons/gi'
import { TbBrandCake, TbBrandCakephp, TbPhotoFilled } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import DropdownButton from '../../../components/ui/dropdown'
import { MenuOption } from '../../../interfaces'

const generalRoutes: MenuOption[] = [
	{
		label: 'Головне меню',
		key: '/',
		icon: GiCakeSlice,
		children: [
			{ key: '/products/1', label: 'Торти', icon: GiCakeSlice },
			{ key: '/products/2', label: 'Десерти', icon: GiCupcake },
			{ key: '/products/3', label: 'Вафельні картинки', icon: TbPhotoFilled }
		]
	},
	{
		label: 'Весільне меню',
		key: '/#',
		icon: GiStairsCake,
		children: [
			{ label: 'Весільні Короваї', key: '/products/4', icon: TbBrandCakephp },
			{ label: 'Весільні Торти', key: '/products/5', icon: GiStairsCake },
			{ label: 'Кенді бари', key: '/products/6', icon: TbBrandCake }
		]
	}
]

const Header: FC = () => {
	return <header className="p-2 fixed top-1 md:top-3 z-50 w-screen">
		<div
			className="bg-neutral-900/60 backdrop-blur-sm max-w-7xl mx-auto container rounded-2xl w-full border border-neutral-700">
			<div
				className="flex justify-between items-center py-3 md:justify-end md:space-x-10 max-w-7xl mx-auto px-4 sm:px-6">
				<div className="flex justify-start lg:w-0 lg:flex-1">

					<img
						className="h-8 w-auto mr-2"
						src="/logo.png"
						alt=""
					/>

					<Link to="/" className="text-2xl text-white">
						OLGACAKE
					</Link>
				</div>

				<div className="flex space-x-2 items-center">
					{generalRoutes.map((value, index) => (
						<DropdownButton key={index} item={value} />
					))}
				</div>
			</div>
		</div>
	</header>
}
export default Header
