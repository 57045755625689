import { FC, useState } from 'react'
import { flushSync } from 'react-dom'
import { BsArrowRight, BsEye } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Tilt } from 'react-tilt'
import { imageUrl } from '../../../data'
import { IProduct } from '../../../interfaces'


const ProductCard: FC<{ product: IProduct }> = ({ product }) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const isProduct = Number(product.categoryID) < 4
	const navigate = useNavigate()

	const onClick = async () => {
		// logEvent('products', 'view-product', `id`, +product.id)
		if (isProduct)
			if ((document as any)?.startViewTransition) {
				(document as any).startViewTransition(() => {
					flushSync(() => navigate(`/product/${product.id}`))
				})
			} else navigate(`/product/${product.id}`)
	}

	return (
		<Tilt options={{
			max: 32,
			perspective: 1000,
			scale: 1.03,
			speed: 1000,
			transition: true
		}}>
			<div
				onClick={onClick}
				style={{ viewTransitionName: `productBox${product.id}` }}
				className="h-min cursor-pointer rounded-xl bg-neutral-800/70 border border-neutral-700 overflow-hidden transition"
			>
				<div
					className="h-52 lg:h-64 overflow-hidden"
					style={{
						height: isProduct ? '208px' : '400px',
						cursor: isProduct ? 'pointer' : 'default',
						viewTransitionName: `productImage${product?.id}`
					}}
				>
					{!imageLoaded && <div className="flex items-center justify-center h-full">
						<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500" />
					</div>
					}
					<img
						className={`h-full w-full object-cover rounded-b-xl object-center ${!imageLoaded && ' hidden'}`}
						src={imageUrl + product.urlToImg}
						alt={`product-img-${product.id}`}
						onLoad={() => setImageLoaded(true)}
					/>
				</div>
				{isProduct && (
					<div className="p-2.5 md:p-4">
						<h1
							className="title-font text-lg font-medium text-neutral-200 mb-2"
							style={{ viewTransitionName: `productTitle${product?.id}` }}
						>
							{product.title}
						</h1>
						<div className="flex items-center flex-wrap">
							<p className="text-purple-500 text-xs md:text-sm inline-flex items-center md:mb-1 lg:mb-0">
								Детальніше
								<BsArrowRight className="w-4 h-4 ml-2" />
							</p>
							<span
								className="text-neutral-400 mr-1 inline-flex items-center ml-auto leading-none text-xs md:text-sm pr-3 py-1">
					<BsEye className="w-4 h-4 mr-1" />
								{product.visiting}
				</span>
						</div>
					</div>
				)}
			</div>
		</Tilt>
	)
}
export default ProductCard
