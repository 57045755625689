import { AnimatePresence } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import Layout from './layouts/home/Layout'
import Loader from './components/ui/loader/Loader'
import AppRouter from './routes/AppRouter'
import { initGA, logPageView } from './utils'

const App: FC = () => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		initGA('G-B3M1ZDYTSC')
		logPageView()
		setTimeout(() => setLoaded(true), 2000)
	}, [])

	return (
		<>
			<AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>
			<Layout>
				<AppRouter />
			</Layout>
		</>
	)
}

export default App
