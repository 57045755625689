import { motion, useScroll, useTransform } from 'framer-motion'
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { IButton, IProduct } from '../../../interfaces'
import ScrollDownButton from '../../ui/scroll-down-butt'
import ProductSliderCard from './ProductSliderCard'

interface IProductsSliderProps {
	products?: IProduct[]
	title: string
	button: IButton
}

const ProductsSlider: FC<IProductsSliderProps> = ({ products, title, button }) => {
	const targetRef = useRef(null)
	const navigate = useNavigate()
	const { scrollYProgress } = useScroll({ target: targetRef })
	const x = useTransform(scrollYProgress, [0, 1], ['1%', '-95%'])


	return (
		<section ref={targetRef} className="relative h-[300vh] bg-neutral-900">
			<div className="sticky top-0 flex h-screen items-center overflow-hidden">
				<div
					className="absolute top-24 lg:top-32 left-1/2 -translate-x-1/2 font-black uppercase">
					<motion.h1
						initial={{ scale: 0, opacity: 0 }}
						whileInView={{ scale: 1.1, opacity: 1 }}
						viewport={{ once: true }}
						className="text-center text-white text-2xl md:text-3xl">
						{title}
					</motion.h1>
				</div>
				<motion.div style={{ x }} className="flex gap-4">
					{products?.map(p => <ProductSliderCard product={p} key={p.id} />)}
				</motion.div>
				<div
					className="absolute bottom-28 lg:bottom-32 left-1/2 -translate-x-1/2">
					<motion.button
						initial={{ scale: 0, opacity: 0 }}
						whileInView={{ scale: 1, opacity: 1 }}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
						transition={{ delay: 0.2 }}
						viewport={{ once: true }}
						className="text-center text-white text-lg md:text-xl font-black px-2 md:px-3 py-1.5 md:py-2 rounded-xl bg-indigo-500 hover:bg-indigo-500 transition-colors"
						onClick={() => navigate(button.url)}
					>
						{button.message}
					</motion.button>
				</div>
				<ScrollDownButton className='absolute bottom-10 md:bottom-14 lg:bottom-16 left-1/2 -translate-x-1/2'/>
			</div>
		</section>
	)
}
export default ProductsSlider
