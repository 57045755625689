import { FC } from 'react'
import { useParams } from 'react-router-dom'
import AppHelmet from '../components/AppHelmet'
import AboutProduct from '../components/product/AboutProduct'
import ProductList from '../components/product/card/ProductList'
import { useCachedKy } from '../hooks'
import { IProduct } from '../interfaces'

const ProductPage: FC = () => {
	const params = useParams()
	const { data } = useCachedKy<IProduct>(`product/${params.id}`)
	const { data: products } = useCachedKy<IProduct[]>(`similarProducts/${params.id}`)

	return (
		<>
			<AppHelmet title={`${data?.title}`} />
			<AboutProduct data={data} id={params.id as string} />
			{products && (
				<ProductList
					title="Схожі:"
					products={products.filter(p => p.id !== params.id)}
				/>
			)}
		</>
	)
}

export default ProductPage
