import { FC, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import ErrorPage from '../pages/ErrorPage'
import HomePage from '../pages/HomePage'
import ProductPage from '../pages/ProductPage'
import ProductsPage from '../pages/ProductsPage'


const ScrollToTop: FC = () => {
	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}


const AppRouter: FC = () => {
	return <>
		<ScrollToTop />
		<Routes>
			<Route path="/" element={<HomePage />} />
			<Route path="*" element={<ErrorPage />} />
			<Route path="/products/:id" element={<ProductsPage />} />
			<Route path="/product/:id" element={<ProductPage />} />
		</Routes>
	</>

}

export default AppRouter
