import { motion } from 'framer-motion'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { imageUrl } from '../../../data'
import { IProduct } from '../../../interfaces'

interface IProductSliderCardProps {
	product: IProduct
}

const ProductSliderCard: FC<IProductSliderCardProps> = ({ product }) => {
	return (
		<motion.div
			key={product.id}
			initial={{ scale: 0, opacity: 0 }}
			whileInView={{ scale: 1, opacity: 1 }}
			viewport={{ once: true }}
			style={{viewTransitionName: `productBox${product.id}`,}}
			className="group relative h-[270px] md:h-[350px] w-[450px] md:w-[650px] overflow-hidden bg-neutral-200 rounded-xl"
		>
			<div
				style={{
					backgroundImage: `url(${imageUrl + product.urlToImg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					viewTransitionName: `productImage${product.id}`
				}}
				className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110 rounded-xl"
			/>
			<div className="absolute inset-0 z-10 grid place-content-center">
				<Link
					to={`/product/${product.id}`}
					style={{ viewTransitionName: `productTitle${product?.id}` }}
					className="bg-gradient-to-br from-white/20 to-white/0 p-8 text-3xl font-black uppercase text-white backdrop-blur-lg rounded-xl"
				>
					{product.title}
				</Link>
			</div>
		</motion.div>
	)
}

export default ProductSliderCard
